<template>
  <div class="pagination flex justify-end items-center text-xs text-grey-dark leading-none">
    <div class="flex">
      <a
        v-if="pagination.index > 1"
        :disabled="loading"
        class="pagination__arrow p-3 inline-block leading-none cursor-pointer"
        :class="{ 'pagination__arrow--disable': loading }"
        @click.prevent="prev"
      >
        <i class="el-icon-arrow-left"></i>
      </a>
      <div class="p-3 bg-white">
        {{ pagination.index }}
      </div>
      <a
        v-if="logs.length >= limit && !pagination.end"
        :disabled="loading"
        class="pagination__arrow p-3 inline-block leading-none cursor-pointer"
        :class="{ 'pagination__arrow--disable': loading }"
        @click.prevent="next"
      >
        <i class="el-icon-arrow-right"></i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'logs_pagination',

  props: {
    logs: {
      default: () => [],
      type: Array,
    },
    limit: {
      default: 15,
      type: Number,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    pagination: {
      default: () => {},
      type: Object,
    },
  },

  methods: {
    prev() {
      this.$emit('prev-page');
    },

    next() {
      this.$emit('next-page');
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  background-color: #E4EDFF;

  &__arrow {
    background-color: #F2F5FF;
    transition: background-color 0.05s ease-in;

    &:hover {
      background-color: darken(#F2F5FF, 1);
    }

    &--disable {
      cursor: progress !important;
      opacity: 0.5;
    }
  }
}
</style>
