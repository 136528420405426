import actions from './actions';

export default {
  namespaced: true,

  state: {
    logs: [],
    cursors: {},
    pagination: {
      index: 1,
      end: false,
    },
  },

  getters: {
    getPagination: st => st.pagination,

    getCursors: st => st.cursors,

    getLogs: st => st.logs,
  },

  mutations: {
    // eslint-disable-next-line no-param-reassign
    SET_LOGS: (st, payload) => {
      // eslint-disable-next-line no-param-reassign
      st.logs = payload.logs;
    },

    SET_CURSORS: (st, payload) => {
      // eslint-disable-next-line no-param-reassign
      st.cursors = payload.cursors;
    },

    SET_PAGINATION: (st, payload) => {
      // eslint-disable-next-line no-param-reassign
      st.pagination = payload.pageInfo;
    },
  },

  actions,
};
