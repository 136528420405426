import http from '../../../utils/http';

export default {
  // Fetch Subaccounts
  // eslint-disable-next-line arrow-body-style
  fetchSubAccounts: async ({ commit }) => {
    try {
      const { data } = await http.get('/voice/sub-accounts/details');
      commit({ type: 'SET_SUBACCOUNTS', subAccounts: data });
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },
};
