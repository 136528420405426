<template>
  <div class="t-logs flex w-full text-xs">
    <div class="w-full">
      <div class="block lg:flex">
        <el-table
          :data="data"
          size="small"
          class="w-full"
          fit
        >

          <!-- Expand -->
          <el-table-column type="expand">
            <template slot-scope="scope">
              <div class="flex flex-wrap">
                <!-- Call ID -->
                <div class="w-1/4">
                  <p class="text-xs font-bold mb-2">{{ $t('column_labels.call_id')}}</p>
                  <p
                    v-if="scope.row.CallId"
                    class="text-xs"
                  >
                    {{ scope.row.CallId }}
                  </p>
                  <p v-else class="text-xs text-grey-dark truncate">—</p>
                </div>

                <!-- Direction -->
                <div class="w-1/4">
                  <p class="text-xs font-bold mb-2">{{ $t('column_labels.direction')}}</p>
                  <p
                    v-if="scope.row.Direction"
                    class="text-xs"
                  >
                    {{ scope.row.Direction }}
                  </p>
                  <p v-else class="text-xs text-grey-dark truncate">—</p>
                </div>

                <!-- UserEndpoint -->
                <div class="w-1/4">
                  <p class="text-xs font-bold mb-2">{{ $t('column_labels.user_endpoint')}}</p>
                  <p
                    v-if="scope.row.UserEndpoint"
                    class="text-xs"
                  >
                    {{ scope.row.UserEndpoint }}
                  </p>
                  <p v-else class="text-xs text-grey-dark truncate">—</p>
                </div>

                <!-- Virtual Number -->
                <div class="w-1/4">
                  <p class="text-xs font-bold mb-2">{{ $t('column_labels.virtual_number')}}</p>
                  <p
                    v-if="scope.row.PlatformEndpoint"
                    class="text-xs"
                  >
                    {{ scope.row.PlatformEndpoint }}
                  </p>
                  <p v-else class="text-xs text-grey-dark truncate">—</p>
                </div>
              </div>

              <div class="flex flex-wrap mt-5">

                <!-- Call Status -->
                <div class="w-1/4">
                  <p class="text-xs font-bold mb-2">{{ $t('column_labels.call_status')}}</p>
                  <p
                    v-if="scope.row.CallStatus"
                    class="text-xs"
                  >
                    {{ scope.row.CallStatus }}
                  </p>
                  <p v-else class="text-xs text-grey-dark truncate">—</p>
                </div>

                <!-- Call Type -->
                <div class="w-1/4">
                  <p class="text-xs font-bold mb-2">{{ $t('column_labels.call_type')}}</p>
                  <p
                    v-if="scope.row.CallType"
                    class="text-xs"
                  >
                    {{ scope.row.CallType }}
                  </p>
                  <p v-else class="text-xs text-grey-dark truncate">—</p>
                </div>

                <!-- Initiated At -->
                <div class="w-1/4">
                  <p class="text-xs font-bold mb-2">{{ getDateHeader(timeZone, $t('column_labels.initiated_at')) }}</p>
                  <p
                    v-if="scope.row.InitiatedAt"
                    class="text-xs"
                  >
                    {{ getLocalDateTimeTz(scope.row.InitiatedAt, timeZoneOffset) }}
                  </p>
                  <p v-else class="text-xs text-grey-dark truncate">—</p>
                </div>

                <!-- ConnectedAt -->
                <div class="w-1/4">
                  <p class="text-xs font-bold mb-2">{{ $t('column_labels.connected_at')}}{{ timeZone }}</p>
                  <p
                    v-if="scope.row.ConnectedAt"
                    class="text-xs"
                  >
                    <span class="block text-grey-dark">
                      {{
                        getLocalDateTimeTz(scope.row.ConnectedAt, timeZoneOffset, '', 'MMM DD YYYY')
                      }}
                    </span>
                    <span class="block text-sm mt-2">
                      {{ $vc.formatTimeWithSeconds(scope.row.ConnectedAt) }}
                    </span>
                  </p>
                  <p v-else class="text-xs text-grey-dark truncate">—</p>
                </div>
              </div>

              <div class="flex flex-wrap mt-5">
                <!-- DisconnectedAt -->
                <div class="w-1/4">
                  <p class="text-xs font-bold mb-2">{{ $t('column_labels.disconnected_at')}} {{ timeZone }}</p>
                  <p
                    v-if="scope.row.DisconnectedAt"
                    class="text-xs"
                  >
                    <span class="block text-grey-dark">
                      {{
                        getLocalDateTimeTz(scope.row.DisconnectedAt,
                          timeZoneOffset,
                          '',
                          'MMM DD YYYY')
                      }}
                    </span>
                    <span class="block text-sm mt-2">
                      {{ $vc.formatTimeWithSeconds(scope.row.DisconnectedAt) }}
                    </span>
                  </p>
                  <p v-else class="text-xs text-grey-dark truncate">—</p>
                </div>
                <!-- DurationInSec -->
                <div class="w-1/4">
                  <p class="text-xs font-bold mb-2">{{ $t('column_labels.duration')}}</p>
                  <p
                    v-if="scope.row.DurationInSec"
                    class="text-xs"
                  >
                    {{ $vc.formatDurationSeconds(scope.row.DurationInSec) }}
                  </p>
                  <p v-else class="text-xs text-grey-dark truncate">—</p>
                </div>

                <!-- Sip Status -->
              <div class="w-1/4">
                <p class="text-xs font-bold mb-2">{{ $t('column_labels.sip_status')}}</p>
                <p
                  v-if="scope.row.SipStatus"
                  class="text-xs"
                >
                  {{ scope.row.SipStatus }}
                </p>
                <p v-else class="text-xs text-grey-dark truncate">—</p>
              </div>

              <!-- Call Quality Score -->
              <div class="w-1/4">
                <p class="text-xs font-bold mb-2">{{ $t('column_labels.call_quality_score')}}</p>
                <p
                  v-if="scope.row.MOS"
                  class="text-xs"
                >
                  {{ `${convertMOStoText(scope.row.MOS)}` }}
                </p>
                <p v-else class="text-xs text-grey-dark truncate">—</p>
              </div>

                <!-- <!&#45;&#45; BilledDurationInSec &#45;&#45;> -->
                <!-- <div class="w&#45;1/4"> -->
                <!--   <p class="text&#45;xs font&#45;bold mb&#45;2">Billed duration</p> -->
                <!--   <p -->
                <!--     v&#45;if="scope.row.BilledDurationInSec" -->
                <!--     class="text&#45;xs" -->
                <!--   > -->
                <!--     {{ $vc.formatDurationSeconds(scope.row.BilledDurationInSec) }} -->
                <!--   </p> -->
                <!--   <p v&#45;else class="text&#45;xs text&#45;grey&#45;dark truncate">—</p> -->
                <!-- </div> -->

              </div>
            </template>
          </el-table-column>

          <!-- Msg direction icon -->
          <el-table-column width="40px">
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.Direction ? $t('column_labels.outbound') : $t('column_labels.inbound')"
                :enterable="false"
                class="help-cursor"
                effect="dark"
                placement="bottom"
              >
                <img :src="getMsgDirectionIcon(scope.row.Direction)" class="block">
              </el-tooltip>
            </template>
          </el-table-column>

          <!-- SubAccountId -->
          <el-table-column
            :label="$tc('column_labels.subaccount_id_cdr', 1)"
            v-slot="scope"
           show-overflow-tooltip
          >
            <div
              v-if="scope.row.SubAccountId"
              class="text-xs truncate"
            >
              {{ scope.row.SubAccountId }}
            </div>
            <div v-else class="text-xs truncate text-grey-dark">—</div>
          </el-table-column>

          <!-- Session ID -->
          <el-table-column
            :label="$t('column_labels.session_id')"
            v-slot="scope"
            show-overflow-tooltip
          >
            <div v-if="scope.row.SessionId">
              <el-tooltip effect="light" content="Copy" placement="bottom"
                ><el-button
                  v-clipboard
                  type="text"
                  icon="el-icon-document"
                  :success-text="$t('app_labels.copied_to_clipboard')"
                  :error-text="$t('errors.unexpected_error')"
                  :data-clipboard-text="scope.row.SessionId"
                  @click="handleCopy(scope.row.SessionId)"
                />
              </el-tooltip>
              <code class="text-xs truncate">
                {{ scope.row.SessionId }}
              </code>
            </div>
            <div v-else class="text-xs truncate text-grey-dark">—</div>
          </el-table-column>

          <!-- Product Type -->
          <el-table-column
            :label="$t('column_labels.product_type')"
            v-slot="scope"
            align="center"
            show-overflow-tooltip
          >
            <div v-if="scope.row.ProductType" class="text-xs truncate">
              {{ scope.row.ProductType }}
            </div>
            <div v-else class="text-xs truncate text-grey-dark">—</div>
          </el-table-column>

          <!-- UserEndpoint Country -->
          <el-table-column
            :label="$t('column_labels.user_endpoint_country')"
            v-slot="scope"
            align="center"
           show-overflow-tooltip
          >
            <div
              v-if="scope.row.UserCountry"
              class="text-xs truncate"
            >
              {{ scope.row.UserCountry }}
            </div>
            <div v-else class="text-xs truncate text-grey-dark">—</div>
          </el-table-column>

          <!-- Virtual Number Country -->
          <el-table-column
            :label="$t('column_labels.virtual_number_country')"
            v-slot="scope"
            align="center"
           show-overflow-tooltip
          >
            <div
              v-if="scope.row.PlatformCountry"
              class="text-xs truncate"
            >
              {{ scope.row.PlatformCountry }}
            </div>
            <div v-else class="text-xs truncate text-grey-dark">—</div>
          </el-table-column>

          <!-- Session Status -->
          <el-table-column
            :label="$t('column_labels.session_status')"
            v-slot="scope"
           show-overflow-tooltip
          >
            <div
              v-if="scope.row.SessionStatus !== null"
              class="text-xs inline-block font-bold text-black rounded-sm vo-tag"
            >
              {{ scope.row.SessionStatus }}
            </div>
            <div v-else class="text-xs truncate text-grey-dark">—</div>
          </el-table-column>

        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import momentDuration from 'moment-duration-format';
import { mapState } from 'vuex';

import phoneFormatter from '@/mixins/phoneFormatter';
import timeFormatter from '@/mixins/timeFormatter';

import incomingMsgIcon from '@/assets/incoming-msg.svg';
import outgoingMsgIcon from '@/assets/outgoing-msg.svg';

momentDuration(moment);

export default {
  name: 'LogsTable',

  mixins: [
    phoneFormatter,
    timeFormatter,
  ],

  props: {
    filters: {
      type: Object,
      default: () => {},
    },
    tabList: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapState('user', ['timeZone', 'timeZoneOffset']),
  },

  methods: {
    getMsgDirectionIcon(dir) {
      let iconUrl = incomingMsgIcon;
      if (dir) {
        iconUrl = outgoingMsgIcon;
      }
      return process.env.VUE_APP_BASE_URL.replace(/\/$/g, '') + iconUrl;
    },

    minLabel(s, u = {}) {
      let unit = u;
      unit = { m: 'min', s: 'sec', ...unit };
      return moment.duration(s, 'second').format(`m [${unit.m}] s [${unit.s}]`);
    },

    handleCopy(sessionId) {
      navigator.clipboard.writeText(sessionId);
    },

    convertMOStoText(mos) {
      let mosText;

      if (mos > 4.34) {
        mosText = 'Best';
      } else if (mos > 4.03) {
        mosText = 'High';
      } else if (mos > 3.6) {
        mosText = 'Medium';
      } else if (mos > 3.1) {
        mosText = 'Low';
      } else if (mos <= 3.1) {
        mosText = 'Poor';
      } else {
        mosText = 'Error';
      }

      return mosText;
    },
  },
};
</script>

<style lang="scss">
.el-table__expanded-cell {
  background: #F6F7F9 !important;
}

.vo-tag {
  line-height: 1;
  padding: 5px 7px 3px;
  text-transform: uppercase;
  text-align: center;
  word-break: break-word;
  white-space: normal;
  line-height: 1;
  display: inline-block;
}
</style>
