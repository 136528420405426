// eslint-disable-next-line import/no-extraneous-dependencies
import Vue from 'vue';
import VueRouter from 'vue-router';
import ElementUI from 'element-ui';
import singleSpaVue from 'single-spa-vue';
import VueI18n from 'vue-i18n';

// import locale from 'element-ui/lib/locale/lang/en';
import App from './App.vue';
import routes from './router';
import store from './store';

// Plugins
import Wc from './plugins/vue-wc';

// Directives
import VueClipboard from './directives/clipboard';

if (window.Bugsnag) {
  window.Bugsnag.getPlugin('vue')
    .installVueErrorHandler(Vue);
}

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: localStorage.getItem('SELECTED_LOCALE'), // set locale
  fallbackLocale: 'en',
});

// Vue.use(ElementUI, { locale });
Vue.use(VueRouter);
Vue.use(Wc);

const router = new VueRouter({
  base: '/voice',
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  // Prevent rerouting if app is unmounted
  if (!window.location.pathname.match(/^\/voice/i)) {
    return 0;
  }

  next();
  return 0;
});

// Directives
Vue.directive('clipboard', VueClipboard);

// eslint-disable-next-line
__webpack_public_path__ = process.env.VUE_APP_BASE_URL

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    el: '#voice',
    i18n,
    router,
    store,
    render(h) {
      // check root config for locale messages
      Object.keys(this.localeMessages).forEach((v) => {
        this.$i18n.setLocaleMessage(v, this.localeMessages[v]);
      });

      Vue.use(ElementUI, {
        i18n: (key, value) => this.$i18n.t(key, value),
      });

      const { localeMessages, langUtil, telemetry } = this;

      Vue.prototype.$telemetry = telemetry;

      return h(App, { props: { localeMessages, langUtil, telemetry } });
    },
    setPublicPath(path) {
      // eslint-disable-next-line
      __webpack_public_path__ = path
    },
  },
});

export const bootstrap = [
  vueLifecycles.bootstrap,
];

export const mount = [
  vueLifecycles.mount,
];

export const unmount = [
  vueLifecycles.unmount,
];
