import moment from 'moment';
import http from '../../../utils/http';

export default {
  // Fetch Subaccounts
  // eslint-disable-next-line arrow-body-style
  fetchLogs: async ({ commit, state }, payload = {}) => {
    try {
      const params = {
        ...payload,
        daterange: payload.daterange.map(dt => moment(dt).utcOffset(0, true).unix()),
      };
      const queryString = Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&');
      const { data } = await http.get(`/voice/logs?${queryString}`);

      if (data.data.length) {
        commit({ type: 'SET_LOGS', logs: data.data });
        commit({ type: 'SET_CURSORS', cursors: { next: data.nextCursor, prev: data.prevCursor } });
        if (payload.pageDirection === 0) {
          commit({
            type: 'SET_PAGINATION',
            pageInfo: {
              index: state.pagination.index + 1,
              end: false,
            },
          });
        } else if (payload.pageDirection === 1) {
          commit({
            type: 'SET_PAGINATION',
            pageInfo: {
              index: state.pagination.index - 1,
              end: false,
            },
          });
        }
      } else {
        // Check if end of page
        commit({
          type: 'SET_PAGINATION',
          pageInfo: {
            index: state.pagination.index,
            end: true,
          },
        });
      }

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },
};
