<template>
  <div>
    <div class="mb-20 flex flex-col">
      <h1 class="text-xl text-color font-semibold page-title mb-5 mt-5 mb-10">Voice CDR</h1>
      <div class="flex -mx-3">
        <div class="px-3 w-full">
          <div class="text-red-light text-sm mb-2">
            Note to user: Data before February 15, 2024 will have a
            longer loading time. Please wait.
          </div>
          <div class="relative">
            <Table
              v-loading="loading"
              :data="logs"
              :filters="filters"
              :tabList="tabsJson"
              @change-page="handleCurrentChange"
            />
          </div>
          <Pagination
            :logs="logs"
            :limit="filters.limit"
            :pagination="pagination"
            :loading="loading"
            @prev-page="prevPage"
            @next-page="nextPage"
          />
        </div>
        <div class="main__aside px-3">
          <div class="pt-20 -mt-20 sticky pin-t">
            <Filters
              :tab="tab"
              :tabList="tabsJson"
              :filters="filters"
              :loading="loading"
              :subaccountList="subAccountsListFiltered"
              :defaultDaterange="defaultDaterange"
              @update-filter="updateFilter"
              @apply-filter="fetchData"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from 'moment';
import {
  mapActions,
  mapMutations,
  mapGetters,
  mapState,
} from 'vuex';

import tabsJson from '@/json/tabs.json';
import directionJson from '@/json/direction.json';
import statusJson from '@/json/status.json';

import Table from './partials/Table.vue';
import Pagination from './partials/Pagination.vue';
import Filters from './partials/Filters.vue';

export default {
  name: 'Reports',

  components: {
    Filters,
    Table,
    Pagination,
  },

  data() {
    const defaultDaterange = [
      Number(Moment().startOf('day').subtract(1, 'w')),
      Number(Moment().endOf('day')),
    ];

    return {
      tabsJson,
      directionJson,
      statusJson,
      defaultDaterange,
      loading: false,

      debounceDefault: 500,

      querySearch: '',
      subAccountsListFiltered: []
    };
  },

  computed: {
    ...mapGetters({
      pagination: 'cdr/getPagination',
      cursors: 'cdr/getCursors',
      hasVoiceMessaging: 'user/getVoiceMessagingAccess',
      logs: 'cdr/getLogs',
      filters: 'common/getCdrfilters',
      subaccountList: 'common/getsubaccounts',
    }),

    ...mapState('user', ['timeZone', 'timeZoneOffset']),
  },

  created() {
    this.fetchSubaccounts();
    this.getUser();
  },

  methods: {
    ...mapActions({
      getSubAccounts: 'common/fetchSubAccounts',
      getLogs: 'cdr/fetchLogs',
      getUser: 'user/fetchUser',
    }),

    ...mapMutations({
      setFilters: 'common/SET_CDR_FILTERS',
      setPagination: 'cdr/SET_PAGINATION',
    }),

    handleCurrentChange(val) {
      this.setPage(val);
    },

    updateFilter(props) {
      delete this.filters.pageCursor;
      delete this.filters.pageDirection;

      this.setFilters({
        ...this.filters,
        ...props,
        dateOffset: this.timeZoneOffset,
      });
    },

    prevPage() {
      const prevPage = this.pagination.index - 1;
      const { limit } = this.filters;

      if (this.pagination.index > 1) {
        this.setFilters({
          ...this.filters,
          pageDirection: 1,
          pageOffset: limit * (prevPage - 1),
          dateOffset: this.timeZoneOffset,
        });

        this.fetchData();
      }
    },

    nextPage() {
      const nextPage = this.pagination.index + 1;
      const { limit } = this.filters;

      this.setFilters({
        ...this.filters,
        pageDirection: 0,
        pageOffset: limit * (nextPage - 1),
        dateOffset: this.timeZoneOffset,
      });

      this.fetchData();
    },

    async fetchData() {
      this.loading = true;
      const filters = {...this.filters};
      const textInputs = ['userCountry', 'platformCountry', 'sessionId', 'sipStatus'];

      textInputs.forEach(field => {
        if (!filters[field] || filters[field] === 'All') {
          filters[field] = 'all';
        }
      });

      try {
        await this.getLogs({
          ...filters,
          dateOffset: this.timeZoneOffset,
        });

        if (this.pagination.end && this.pagination.index > 1) {
          this.$message.warning(this.$t('errors.no_more_data'));
        }
      } catch (err) {
        // this.showMessage(err.message, 'error');
        this.$showError(this, err);
      } finally {
        this.loading = false;
      }
    },

    async fetchSubaccounts() {
      this.loading = true;
      try {
        await this.getSubAccounts();

        
        // Set default value of subaccount
        if (this.subaccountList.length) {
          const subAccountsListFiltered = this.subaccountList.map(sa => {
            return {
              id: sa.SubAccountId,
              label: sa.SubAccountId
            }
          });

          this.subAccountsListFiltered = subAccountsListFiltered;
          
          this.updateFilter({
            daterange: this.filters.daterange.length
              ? this.filters.daterange
              : this.defaultDaterange,
            subAccountId: this.filters.subAccountId || this.subAccountsListFiltered[0].id,
            productType: this.filters.productType || this.tabsJson[0].value,
            direction: this.filters.direction || this.directionJson[0].value,
            status: this.filters.status || this.statusJson[0].value,
            search: '',
            dateOffset: this.timeZoneOffset,
            userCountry: this.filters.userCountry,
            platformCountry: this.filters.platformCountry,
            sessionId: this.filters.sessionId,
            sipStatus: this.filters.sipStatus,
            callQuality: this.filters.callQuality
          });

          this.fetchData();
        } else {
          this.loading = false;
        }
      } catch (err) {
        // this.showMessage(err.message, 'error');
        this.$showError(this, err);
        this.loading = false;
      }
    },

    showMessage(message, type) {
      this.$message({
        showClose: true,
        message,
        type,
      });
    },
  },
};
</script>

<style lang="scss">
// Add this override to hide border in tabs for this page
.reports-tab .el-tabs__nav-wrap::after {
  height: 0px;
  background: none;
}
</style>
