<template>
  <div class="t-logs flex w-full text-xs">
    <div class="w-full">
      <div class="block lg:flex">
        <el-table
          :data="data"
          size="small"
          class="w-full"
          fit
        >

          <!-- Expand -->
          <el-table-column type="expand">
            <template slot-scope="scope">
              <div class="flex flex-wrap">

                <div class="w-1/4">
                  <p class="text-xs font-bold mb-2">{{ $t('column_labels.caller')}}</p>
                  <p
                    v-if="scope.row.Caller"
                    class="text-xs"
                  >
                    {{ $vc.formatPhoneNumber(scope.row.Caller) }}
                  </p>
                  <p v-else class="text-xs text-grey-dark truncate">—</p>
                </div>

                <!-- Reciever -->
                <div class="w-1/4">
                  <p class="text-xs font-bold mb-2">{{ $t('column_labels.receiver')}}</p>
                  <p
                    v-if="scope.row.Receiver"
                    class="text-xs"
                  >
                    {{ $vc.formatPhoneNumber(scope.row.Receiver) }}
                  </p>
                  <p v-else class="text-xs text-grey-dark truncate">—</p>
                </div>

                <!-- ConnectedAt -->
                <div class="w-1/4">
                  <p class="text-xs font-bold mb-2">{{ $t('column_labels.call_started_at')}}{{ timeZone }}</p>
                  <p
                    v-if="scope.row.ConnectedAt"
                    class="text-xs"
                  >
                    <span class="block text-grey-dark">
                      {{
                        getLocalDateTimeTz(scope.row.ConnectedAt, timeZoneOffset, '', 'MMM DD YYYY')
                      }}
                    </span>
                    <span class="block text-sm mt-2">
                      {{ $vc.formatTimeWithSeconds(scope.row.ConnectedAt) }}
                    </span>
                  </p>
                  <p v-else class="text-xs text-grey-dark truncate">—</p>
                </div>


                <!-- DisconnectedAt -->
                <div class="w-1/4">
                  <p class="text-xs font-bold mb-2">{{ $t('column_labels.call_disconnected_at')}} {{ timeZone }}</p>
                  <p
                    v-if="scope.row.DisconnectedAt"
                    class="text-xs"
                  >
                    <span class="block text-grey-dark">
                      {{
                        getLocalDateTimeTz(scope.row.DisconnectedAt,
                          timeZoneOffset,
                          '',
                          'MMM DD YYYY')
                      }}
                    </span>
                    <span class="block text-sm mt-2">
                      {{ $vc.formatTimeWithSeconds(scope.row.DisconnectedAt) }}
                    </span>
                  </p>
                  <p v-else class="text-xs text-grey-dark truncate">—</p>
                </div>
              </div>

              <div class="flex flex-wrap mt-5">
                <!-- DurationInSec -->
                <div class="w-1/4">
                  <p class="text-xs font-bold mb-2">{{ $t('column_labels.duration')}}</p>
                  <p
                    v-if="scope.row.DurationInSec"
                    class="text-xs"
                  >
                    {{ $vc.formatDurationSeconds(scope.row.DurationInSec) }}
                  </p>
                  <p v-else class="text-xs text-grey-dark truncate">—</p>
                </div>

                <!-- <!&#45;&#45; BilledDurationInSec &#45;&#45;> -->
                <!-- <div class="w&#45;1/4"> -->
                <!--   <p class="text&#45;xs font&#45;bold mb&#45;2">Billed duration</p> -->
                <!--   <p -->
                <!--     v&#45;if="scope.row.BilledDurationInSec" -->
                <!--     class="text&#45;xs" -->
                <!--   > -->
                <!--     {{ $vc.formatDurationSeconds(scope.row.BilledDurationInSec) }} -->
                <!--   </p> -->
                <!--   <p v&#45;else class="text&#45;xs text&#45;grey&#45;dark truncate">—</p> -->
                <!-- </div> -->

              </div>
            </template>
          </el-table-column>

          <!-- Msg direction icon -->
          <el-table-column width="40px">
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.Direction ? $t('column_labels.outbound') : $t('column_labels.inbound')"
                :enterable="false"
                class="help-cursor"
                effect="dark"
                placement="bottom"
              >
                <img :src="getMsgDirectionIcon(scope.row.Direction)" class="block">
              </el-tooltip>
            </template>
          </el-table-column>

          <!-- Subaccount -->
          <el-table-column
            :label="$tc('column_labels.subaccount', 1)"
            v-slot="scope"
           show-overflow-tooltip
          >
            <div
              v-if="scope.row.SubAccountId"
              class="text-xs truncate"
            >
              {{ scope.row.SubAccountId }}
            </div>
            <div v-else class="text-xs truncate text-grey-dark">—</div>
          </el-table-column>

          <!-- Date -->
          <el-table-column
            :label="getDateHeader(timeZone, $t('column_labels.date'))"
            v-slot="scope"
            align="center"
           show-overflow-tooltip
          >
            <div
              v-if="scope.row.InitiatedAt"
              class="text-xs truncate"
            >
              {{ getLocalDateTimeTz(scope.row.InitiatedAt, timeZoneOffset) }}
            </div>
            <div v-else class="text-xs truncate text-grey-dark">—</div>
          </el-table-column>

          <!-- Caller -->
          <el-table-column
            :label="$t('column_labels.caller')"
            v-slot="scope"
            show-overflow-tooltip
          >
            <div
              v-if="scope.row.Caller"
              class="text-xs truncate"
            >
              {{ $vc.formatPhoneNumber(scope.row.Caller) }}
            </div>
            <div v-else class="text-xs truncate text-grey-dark">—</div>
          </el-table-column>

          <!-- Receiver -->
          <el-table-column
            :label="$t('column_labels.receiver')"
            v-slot="scope"
           show-overflow-tooltip
          >
            <div
              v-if="scope.row.Receiver"
              class="text-xs truncate"
            >
              {{ $vc.formatPhoneNumber(scope.row.Receiver) }}
            </div>
            <div v-else class="text-xs truncate text-grey-dark">—</div>
          </el-table-column>

          <!-- Session -->
          <el-table-column
            :label="$t('column_labels.session')"
            v-slot="scope"
            align="right"
           show-overflow-tooltip
          >
            <code
              v-if="scope.row.SessionId"
              class="text-xs text-black font-mono p-1 px-2 bg-grey-lighter rounded-sm leading-none"
            >
              {{ scope.row.SessionId }}
            </code>
            <div v-else class="text-xs truncate text-grey-dark">—</div>
          </el-table-column>

          <!-- Duration -->
          <el-table-column
            :label="$t('column_labels.duration')"
            v-slot="scope"
            align="center"
           show-overflow-tooltip
          >
            <div
              v-if="scope.row.DurationInSec"
              class="text-xs truncate font-bold"
            >
              {{ $vc.formatDurationSeconds(scope.row.DurationInSec) }}
            </div>
            <div v-else class="text-xs truncate text-grey-dark">—</div>
          </el-table-column>

          <!-- Status -->
          <el-table-column
            :label="$t('column_labels.status')"
            v-slot="scope"
           show-overflow-tooltip
          >
            <div
              v-if="scope.row.Status !== null"
              class="text-xs inline-block font-bold text-black rounded-sm vo-tag"
            >
              {{ scope.row.Status }}
            </div>
            <div v-else class="text-xs truncate text-grey-dark">—</div>
          </el-table-column>

        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import momentDuration from 'moment-duration-format';
import { mapState } from 'vuex';

import phoneFormatter from '@/mixins/phoneFormatter';
import timeFormatter from '@/mixins/timeFormatter';

import incomingMsgIcon from '@/assets/incoming-msg.svg';
import outgoingMsgIcon from '@/assets/outgoing-msg.svg';

momentDuration(moment);

export default {
  name: 'LogsTable',

  mixins: [
    phoneFormatter,
    timeFormatter,
  ],

  props: {
    filters: {
      type: Object,
      default: () => {},
    },
    tabList: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapState('user', ['timeZone', 'timeZoneOffset']),
  },

  methods: {
    getMsgDirectionIcon(dir) {
      let iconUrl = incomingMsgIcon;
      if (dir) {
        iconUrl = outgoingMsgIcon;
      }
      return process.env.VUE_APP_BASE_URL.replace(/\/$/g, '') + iconUrl;
    },

    minLabel(s, u = {}) {
      let unit = u;
      unit = { m: 'min', s: 'sec', ...unit };
      return moment.duration(s, 'second').format(`m [${unit.m}] s [${unit.s}]`);
    },
  },
};
</script>

<style lang="scss">
.el-table__expanded-cell {
  background: #F6F7F9 !important;
}

.vo-tag {
  line-height: 1;
  padding: 5px 7px 3px;
  text-transform: uppercase;
  text-align: center;
  word-break: break-word;
  white-space: normal;
  line-height: 1;
  display: inline-block;
}
</style>
