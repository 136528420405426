<template>
  <div class="relative">
    <div class="flex p-4 pb-8 -mb-4 rounded-t bg-grey-lightest">
      <div class="w-full">
        <span class="text-xs block mb-2">{{ $t('column_labels.date_range') }} {{ timeZone }}</span>
        <el-date-picker
          v-model="dates"
          type="daterange"
          format="dd MMM yyyy"
          unlink-panels
          range-separator="—"
          :start-placeholder="$t('date_labels.start_date')"
          :end-placeholder="$t('date_labels.end_date')"
          size="small"
          class="vo-datepicker"
          :picker-options="pickerOptions"
          @change="v => updateFilter({ daterange: v })"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="flex flex-col rounded p-4 w-full justify-between text-black bg-grey-lighter">
      <h3 class="font-medium text-black text-sm mb-5">
        {{ $t('app_labels.filters')}}
      </h3>
      <div class="px-1">
        <span class="text-xs block mb-2">{{ $tc('column_labels.subaccount', 1) }}</span>
        <el-select
          :value="filters.subAccountId"
          :disabled="loading"
          class="w-full"
          size="small"
          filterable
          @change="v => updateFilter({ subAccountId: v })"
          >
          <el-option
            v-for="subaccount in [{id: 'all', label: $t('voice.logs.filters[1]')}, ...subaccountList]"
            :key="subaccount.id"
            :label="subaccount.label"
            :value="subaccount.id"
            >
          </el-option>
        </el-select>
      </div>
      <div class="mt-4 px-1">
        <span class="text-xs block mb-2">{{ $t('column_labels.product_type') }}</span>
        <el-select
          :value="filters.productType"
          :disabled="loading"
          class="w-full"
          size="small"
          filterable
          @change="v => updateFilter({ productType: v })"
          >
          <el-option
            v-for="product in productList"
            :key="product.value"
            :label="product.name"
            :value="product.value"
            >
          </el-option>
        </el-select>
      </div>

      <div class="mt-4 px-1">
        <span class="text-xs block mb-2">{{ $t('column_labels.direction') }}</span>
        <el-select
          :value="filters.direction"
          :disabled="loading"
          class="w-full"
          size="small"
          filterable
          @change="v => updateFilter({ direction: v })"
          >
          <el-option
            v-for="direction in directionJson"
            :key="direction.name"
            :label="$t(`voice.directions.${direction.name}`)"
            :value="direction.value"
            >
          </el-option>
        </el-select>
      </div>

      <div class="mt-4 px-1">
        <span class="text-xs block mb-2">User Country</span>
        <el-input
          :value="filters.userCountry"
          :disabled="loading"
          class="w-full"
          size="small"
          @input="v => updateFilter({ userCountry: v })"
          >
        </el-input>
      </div>

      <div class="mt-4 px-1">
        <span class="text-xs block mb-2">Platform Country</span>
        <el-input
          :value="filters.platformCountry"
          :disabled="loading"
          class="w-full"
          size="small"
          @input="v => updateFilter({ platformCountry: v })"
          >
        </el-input>
      </div>

      <div class="mt-4 px-1">
        <span class="text-xs block mb-2">SIP Status</span>
        <el-input
          :value="filters.sipStatus"
          :disabled="loading"
          class="w-full"
          size="small"
          @input="v => updateFilter({ sipStatus: v })"
          >
        </el-input>
      </div>

      <div class="mt-4 px-1">
        <span class="text-xs block mb-2">Call Quality Score (MOS)</span>
        <el-select
          :value="filters.callQuality"
          :disabled="loading"
          class="w-full"
          size="small"
          filterable
          @change="v => updateFilter({ callQuality: v })"
          >
          <el-option
            v-for="mos in mosJson"
            :key="mos.name"
            :label="mos.name"
            :value="mos.value"
            >
          </el-option>
        </el-select>
      </div>

      <div class="w-full mt-4 px-1 text-center">
        <el-button
          type="primary"
          size="medium"
          :disabled="loading"
          @click.prevent="applyFilter">
          <span>
            {{ $t('actions.apply_filter') }}
          </span>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapState, mapMutations } from 'vuex';
import Moment from 'moment';
import directionJson from '@/json/direction.json';
import mosJson from '@/json/mos.json';
import statusJson from '@/json/status.json';

export default {
  name: 'filter',

  props: {
    filters: {
      default: () => {},
      type: Object,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    subaccountList: {
      default: () => [],
      type: Array,
    },
    defaultDaterange: {
      default: () => [],
      type: Array,
    },
    tab: {
      type: String,
      default: '',
    },
    tabList: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapState('user', ['timeZone', 'timeZoneOffset']),
  },

  data() {
    // Change the name in 'All' product list
    const productList = { ...this.tabList };
    productList[0].name = this.$t('voice.logs.filters[0]');

    const self = this;

    return {
      directionJson,
      statusJson,
      mosJson,
      dates: [],
      search: '',
      productList,
      pickerOptions: {
        disabledDate(time) {
          return Moment() < time || Moment().diff(time, 'months') >= 6;
        },
        shortcuts: [
          {
            text: self.$t('date_labels.today'),
            onClick(picker) {
              const end = Moment().endOf('day');
              const start = Moment().startOf('day');
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: self.$t('date_labels.last_7_days'),
            onClick(picker) {
              const start = Moment().subtract(1, 'week').startOf('day');
              const end = Moment().endOf('day');
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: self.$t('date_labels.last_14_days'),
            onClick(picker) {
              const start = Moment().subtract(2, 'week').startOf('day');
              const end = Moment().endOf('day');
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: self.$t('date_labels.last_30_days'),
            onClick(picker) {
              const start = Moment().subtract(30, 'days').startOf('day');
              const end = Moment().endOf('day');
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },

  created() {
    this.dates = this.filters.daterange.length
      ? this.filters.daterange
      : this.defaultDaterange;
  },

  methods: {
    ...mapMutations({
      setFilters: 'common/SET_CDR_FILTERS',
      setPagination: 'cdr/SET_PAGINATION',
    }),

    updateFilter(v) {
      this.$emit('update-filter', v);
    },

    applyFilter(v) {
      this.setFilters({
        ...this.filters,
        pageOffset: 0,
      });

      this.setPagination({
        pageInfo: {
          index: 1,
          end: false,
        },
      });

      this.$emit('apply-filter', v);
    },

    // eslint-disable-next-line func-names
    handleSearch: debounce(function (s) {
      // remove space on the search string
      this.updateFilter({ search: s.replace(/\s+/g, '') });
    }, 300),
  },
};
</script>

<style scoped lang="scss">
.vo-datepicker {
  width: 100% !important;
}
</style>
