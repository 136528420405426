<template>
  <div>
    <div class="mb-20 flex flex-col">
      <h1 class="text-xl text-color font-semibold page-title mb-5 mt-5 mb-5 mt-5">{{ $t('voice.logs.title') }}</h1>
      <div class="flex -mx-3">
        <div class="px-3 w-full">
          <div class="relative">
            <div class="flex justify-between mb-10 items-center">
              <span class="text-sm text-grey block mr-10">
                {{ $t('voice.logs.index[0]') }}
              </span>
              <div class="ml-auto">
                <!-- Search filter -->
                <div class="w-full">
                  <el-input
                    v-model="querySearch"
                    :disabled="loading"
                    :debounce="debounceDefault"
                    suffix-icon="el-icon-search"
                    :placeholder="$t('voice.logs.index[1]')"
                    size="small"
                    class="w-64"
                    @change="s => updateFilter({ search: s.replace(/\s+/g, '') })"
                  />
                </div>
              </div>
            </div>
            <Table
              v-loading="loading"
              :data="logs"
              :filters="filters"
              :tabList="tabsJson"
              @change-page="handleCurrentChange"
            />
          </div>
          <Pagination
            :logs="logs"
            :limit="filters.limit"
            :pagination="pagination"
            :loading="loading"
            @prev-page="prevPage"
            @next-page="nextPage"
          />
        </div>
        <div class="main__aside px-3">
          <div class="pt-20 -mt-20 sticky pin-t">
            <Filters
              :tab="tab"
              :tabList="tabsJson"
              :filters="filters"
              :loading="loading"
              :subaccountList="subaccountList"
              :defaultDaterange="defaultDaterange"
              @update-filter="updateFilter"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from 'moment';
import {
  mapActions,
  mapMutations,
  mapGetters,
  mapState,
} from 'vuex';

import tabsJson from '@/json/tabs.json';
import directionJson from '@/json/direction.json';
import statusJson from '@/json/status.json';

import Table from './partials/Table.vue';
import Pagination from './partials/Pagination.vue';
import Filters from './partials/Filters.vue';

export default {
  name: 'Reports',

  components: {
    Filters,
    Table,
    Pagination,
  },

  data() {
    const defaultDaterange = [
      Number(Moment().startOf('day').subtract(1, 'w')),
      Number(Moment().endOf('day')),
    ];

    return {
      tabsJson,
      directionJson,
      statusJson,
      defaultDaterange,
      loading: false,

      debounceDefault: 500,

      querySearch: '',
    };
  },

  computed: {
    ...mapGetters({
      pagination: 'rlogs/getPagination',
      cursors: 'rlogs/getCursors',
      hasVoiceMessaging: 'user/getVoiceMessagingAccess',
      logs: 'rlogs/getLogs',
      filters: 'common/getfilters',
      subaccountList: 'common/getsubaccounts',
    }),

    ...mapState('user', ['timeZone', 'timeZoneOffset']),
  },

  created() {
    this.fetchSubaccounts();
    this.getUser();
  },

  methods: {
    ...mapActions({
      getSubAccounts: 'common/fetchSubAccounts',
      getLogs: 'rlogs/fetchLogs',
      getUser: 'user/fetchUser',
    }),

    ...mapMutations({
      setFilters: 'common/SET_FILTERS',
      setPagination: 'rlogs/SET_PAGINATION',
    }),

    handleCurrentChange(val) {
      this.setPage(val);
    },

    updateFilter(props) {
      delete this.filters.pageCursor;
      delete this.filters.pageDirection;

      this.setFilters({
        ...this.filters,
        ...props,
        offset: this.timeZoneOffset,
      });

      // Fetch data
      this.fetchData();

      this.setPagination({
        pageInfo: {
          index: 1,
          end: false,
        },
      });
    },

    prevPage() {
      const props = {
        pageCursor: this.cursors.prev,
        pageDirection: 1,
      };

      if (this.pagination.index > 1) {
        this.setFilters({
          ...this.filters,
          ...props,
          offset: this.timeZoneOffset,
        });

        this.fetchData();
      }
    },

    nextPage() {
      const props = {
        pageCursor: this.cursors.next,
        pageDirection: 0,
      };

      this.setFilters({
        ...this.filters,
        ...props,
        offset: this.timeZoneOffset,
      });

      this.fetchData();
    },

    async fetchData() {
      this.loading = true;
      try {
        await this.getLogs({
          ...this.filters,
          offset: this.timeZoneOffset,
        });

        if (this.pagination.end && this.pagination.index > 1) {
          this.$message.warning(this.$t('errors.no_more_data'));
        }
      } catch (err) {
        // this.showMessage(err.message, 'error');
        this.$showError(this, err);
      } finally {
        this.loading = false;
      }
    },

    async fetchSubaccounts() {
      this.loading = true;
      try {
        await this.getSubAccounts();

        // Set default value of subaccount
        if (this.subaccountList.length) {
          this.updateFilter({
            daterange: this.filters.daterange.length
              ? this.filters.daterange
              : this.defaultDaterange,
            subAccountId: this.filters.subAccountId || this.subaccountList[0].SubAccountUid,
            type: this.filters.type || this.tabsJson[0].value,
            direction: this.filters.direction || this.directionJson[0].value,
            status: this.filters.status || this.statusJson[0].value,
            search: '',
            offset: this.timeZoneOffset,
          });
        } else {
          this.loading = false;
        }
      } catch (err) {
        // this.showMessage(err.message, 'error');
        this.$showError(this, err);
        this.loading = false;
      }
    },

    showMessage(message, type) {
      this.$message({
        showClose: true,
        message,
        type,
      });
    },
  },
};
</script>

<style lang="scss">
// Add this override to hide border in tabs for this page
.reports-tab .el-tabs__nav-wrap::after {
  height: 0px;
  background: none;
}
</style>
