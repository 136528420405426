<template>
  <div class="relative">
    <div class="flex p-4 pb-8 -mb-4 rounded-t bg-grey-lightest">
      <div class="w-full">
        <span class="text-xs block mb-2">{{ $t('column_labels.date_range') }} {{ timeZone }}</span>
        <el-date-picker
          v-model="dates"
          type="daterange"
          format="dd MMM yyyy"
          unlink-panels
          range-separator="—"
          :start-placeholder="$t('date_labels.start_date')"
          :end-placeholder="$t('date_labels.end_date')"
          size="small"
          class="vo-datepicker"
          :picker-options="pickerOptions"
          @change="v => updateFilter({ daterange: v })"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="flex flex-col rounded p-4 w-full justify-between text-black bg-grey-lighter">
      <h3 class="font-medium text-black text-sm mb-5">
        {{ $t('app_labels.filters')}}
      </h3>
      <div class="px-1">
        <span class="text-xs block mb-2">{{ $t('column_labels.products') }}</span>
        <el-select
          :value="filters.type"
          :disabled="loading"
          class="w-full"
          size="small"
          filterable
          @change="v => updateFilter({ type: v })"
          >
          <el-option
            v-for="product in productList"
            :key="product.value"
            :label="product.name"
            :value="product.value"
            >
          </el-option>
        </el-select>
      </div>
      <div class="mt-4 px-1">
        <span class="text-xs block mb-2">{{ $tc('column_labels.subaccount', 1) }}</span>
        <el-select
          :value="filters.subAccountId"
          :disabled="loading"
          class="w-full"
          size="small"
          filterable
          @change="v => updateFilter({ subAccountId: v })"
          >
          <el-option
            v-for="subaccount in [{SubAccountUid: 'all', SubAccountId: $t('voice.logs.filters[1]')}, ...subaccountList]"
            :key="subaccount.SubAccountUid"
            :label="subaccount.SubAccountId"
            :value="subaccount.SubAccountUid"
            >
          </el-option>
        </el-select>
      </div>
      <div class="mt-4 px-1">
        <span class="text-xs block mb-2">{{ $t('column_labels.direction') }}</span>
        <el-select
          :value="filters.direction"
          :disabled="loading"
          class="w-full"
          size="small"
          filterable
          @change="v => updateFilter({ direction: v })"
          >
          <el-option
            v-for="direction in directionJson"
            :key="direction.name"
            :label="$t(`voice.directions.${direction.name}`)"
            :value="direction.value"
            >
          </el-option>
        </el-select>
      </div>
      <div class="mt-4 px-1">
        <span class="text-xs block mb-2">{{ $t('column_labels.status') }}</span>
        <el-select
          :value="filters.status"
          :disabled="loading"
          class="w-full"
          size="small"
          filterable
          @change="v => updateFilter({ status: v })"
          >
          <el-option
            v-for="status in statusJson"
            :key="status.name"
            :label="$t(`voice.status.${status.name}`)"
            :value="status.value"
            >
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapState } from 'vuex';
import Moment from 'moment';
import directionJson from '@/json/direction.json';
import statusJson from '@/json/status.json';

export default {
  name: 'filter',

  props: {
    filters: {
      default: () => {},
      type: Object,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    subaccountList: {
      default: () => [],
      type: Array,
    },
    defaultDaterange: {
      default: () => [],
      type: Array,
    },
    tab: {
      type: String,
      default: '',
    },
    tabList: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapState('user', ['timeZone', 'timeZoneOffset']),
  },

  data() {
    // Change the name in 'All' product list
    const productList = { ...this.tabList };
    productList[0].name = this.$t('voice.logs.filters[0]');

    const self = this;

    return {
      directionJson,
      statusJson,
      dates: [],
      search: '',
      productList,
      pickerOptions: {
        disabledDate(time) {
          return Moment() < time || Moment().diff(time, 'months') >= 6;
        },
        shortcuts: [
          {
            text: self.$t('date_labels.today'),
            onClick(picker) {
              const end = Moment().endOf('day');
              const start = Moment().startOf('day');
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: self.$t('date_labels.last_7_days'),
            onClick(picker) {
              const start = Moment().subtract(1, 'week').startOf('day');
              const end = Moment().endOf('day');
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: self.$t('date_labels.last_14_days'),
            onClick(picker) {
              const start = Moment().subtract(2, 'week').startOf('day');
              const end = Moment().endOf('day');
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: self.$t('date_labels.last_30_days'),
            onClick(picker) {
              const start = Moment().subtract(30, 'days').startOf('day');
              const end = Moment().endOf('day');
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },

  created() {
    this.dates = this.filters.daterange.length
      ? this.filters.daterange
      : this.defaultDaterange;
  },

  methods: {
    updateFilter(v) {
      this.$emit('update-filter', v);
    },

    // eslint-disable-next-line func-names
    handleSearch: debounce(function (s) {
      // remove space on the search string
      this.updateFilter({ search: s.replace(/\s+/g, '') });
    }, 300),
  },
};
</script>

<style scoped lang="scss">
.vo-datepicker {
  width: 100% !important;
}
</style>
